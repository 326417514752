// PHOTO SLIDER
//  minify at http://refresh-sf.com/yui/

addLoadEvent(initializePhotoSliders);

function initializePhotoSliders()
{

// --- Prepare all Photosliders with propper markup ----

var AutoSlide = [],
	timeout = [];

	$('.PhotoSlider').each(function(index){
																		
		var $PS = $(this);
			$PS.wrapInner('<div class="Border ClearFix"><div class="Overflow"></div></div>')
				.find('.Border').append('<div class="Buttons ClearFix"></div>')
				.find('.Overflow')
				.children('ul').addClass('Container')
				.children('li').addClass('Slide')
				.eq(0).addClass('Active');
			
			$PS.find('li.Slide:first').addClass('First');
			$PS.find('li.Slide:last').addClass('Last');
			
			
	// ----------- OPTIONS START -----------

		// manually set slide widths
			if ($PS.attr('class').match(/^.*SlideW(\d+).*/)!==null){  // if class of ShowXXX exists then
				var slideWidth = $PS.attr('class').match(/^.*SlideW(\d+).*/)[1];
				$PS.find('li.Slide').width( parseInt(slideWidth));
			}
		// captions
			if (!$PS.hasClass('HideCaptions')){
				$PS.find('.Buttons').before('<p class="PhotoCaption"></p>');
			}
		// navigation arrows
			if (!$PS.hasClass('HideArrows')){
				$PS.find('.Buttons').append('<a class="Prev" href="javascript:;"></a><a class="Next" href="javascript:;"></a>');
			}
		// navigation links
			if ($PS.hasClass('ShowNav') || $PS.hasClass('Vertical')) {
				var slides = $PS.find('li.Slide').length,
					navMarkup = '<div class="SlideNav ClearFix"><ul>',
					i=1;
				for (i=1;i<=slides;i++) { navMarkup += '<li><a href="javascript:;"><span>' + i + '</span></a></li>'; }	// create a nav li for each slide
				navMarkup += '</ul></div>';
				$PS.find('.Buttons').append(navMarkup);  // inject the new markup into the slider
				$PS.find('.SlideNav li:first').addClass('First');
				$PS.find('.SlideNav li:last').addClass('Last');
			}
			else{
		// slide numbers
				if (!$PS.hasClass('HideNumbers')){
					$PS.find('.Buttons').append('<em class="Count"><span></span>&nbsp;of&nbsp;<span></span></em>');					
				}
			}
		// if both Numbers and Captions are hidden, add an empty <em> to make space for the nav buttons	
			if ( $PS.hasClass('HideNumbers') && $PS.hasClass('HideCaptions') ) { $PS.find('.Buttons').prepend('<em>&nbsp;</em>'); }
		
		// Vertical
			if ($PS.hasClass('Vertical')){
				$PS.find('.Border').addClass('Vertical');
			}
			
		// setting $PS width - including Manual Width
			var ContainerW=0, MaxW=0, MaxH = 0,
				borderW =  parseInt($PS.find('.Border').css('borderBottomWidth'))*2,
				PSPaddingL = parseInt($PS.css('padding-left')),
				PSPaddingR = parseInt($PS.css('padding-right'));
		
			$PS.find('.Slide').each(function(){ if ($(this).outerWidth(true) > MaxW) { MaxW = $(this).outerWidth(true); } });

			// Are we setting the width manually?
				var SetWidth = $PS.attr('class').match(/^.*Width(\d+).*/), //detect to see if has a WidthXXX class where XXXX is a number
					newWidth;
				if (SetWidth!==null || $PS.hasClass('FullWidth') ){  // does it have WidthXXXX or FullWidth classes?
					// find the parent's full width and set the fullwidth minus the padding
						if ($PS.hasClass('FullWidth')){
							// hide it then calculate the parent width then show it - otherwise, the content will push itself outside the parent
								$PS.hide(); 
								newWidth = $PS.parent().width()-PSPaddingL-PSPaddingR;
								$PS.show();
							} 
							else { 
								newWidth = parseInt(SetWidth[1]); 
							}
					// set the $PS width
						$PS.width(newWidth).addClass('manualWidth');
					// set the Overflow with
						$PS.find('.Overflow').width( newWidth-borderW );
					// calculate the new width of the slides to include the padding of the slider so the slide doesn't get cut off
						var PSiW = $PS.width() - $PS.css('padding-left').replace('px','') - $PS.css('padding-right').replace('px','');
						$PS.find('.Slide').width(PSiW).addClass('SlideContent'); 
				}
			// Show multiple slides at once
				else if ($PS.attr('class').match(/^.*Show(\d+).*/)!==null){  // if class of ShowXXX exists then
					$PS.addClass('manualWidth').addClass('MultiSlide');
					var showSlides = $PS.attr('class').match(/^.*Show(\d+).*/)[1],
						slideWidth = $PS.find('li.Slide.Active').outerWidth(),
						lastSlide = $PS.find('li.Slide').length - showSlides ;
					$PS.width(showSlides * slideWidth + borderW);
					$PS.find('.Overflow').width(showSlides*slideWidth);
					$PS.find('li.Slide').eq(lastSlide).addClass('LastSlideToShow');
				}
				else {
					// it's just a normal slide....
						$PS.width(MaxW+borderW);
				}
				
			// set the container width to fit all slides
				$PS.find('li.Slide').each(function(){ ContainerW += $(this).outerWidth(true); });
				$PS.find('.Container').width(ContainerW+100); // added 100 for good measure
			
		
	// ----------- AUTOSLIDE START -----------
		if ($PS.attr('class').match(/^.*AutoSlide.*/)!==null){  // if class of AutoSlide or AutoSlideXXXX exists then
			$PS.addClass('Continuous');
			var timeoutDefault = 4000;
			if ( $PS.attr('class').match(/^.*AutoSlide(\d+).*/) ){ // if class of AutoSlideXXXX exists then over-ride the timing
				timeoutDefault = $PS.attr('class').match(/^.*AutoSlide(\d+).*/)[1];
			} 
			timeout[CPI($PS)] = timeoutDefault;
		// create variable and save in AutoSlide array to set the interval -- that way multiple autoslides on one page work
			AutoSlide[CPI($PS)] = setInterval( function(){ updateSlide(CPI($PS)) }, timeoutDefault );
		}
	// ----------- AUTOSLIDE END -----------

		updateSlide(CPI(this),0,true);


	}); // -------- end photoslider each --------------
	
	// AutoSlide hover to pause...
		$('.PhotoSlider[class*="AutoSlide"]').hover(
			function() { var arr1 = CPI($(this)); $(this).addClass('Paused'); clearInterval(AutoSlide[arr1]);   },
			function() { var arr2 = CPI($(this)); $(this).removeClass('Paused'); AutoSlide[arr2] = setInterval( function(){ updateSlide(arr2)}, timeout[arr2] ); }
		);	

	
// Photoslider Navigation Buttons
	// arrow navigation
		$('.PhotoSlider .Buttons .Prev').on('click',function(e){
			var $PS = $(this).closest('.PhotoSlider');
			var nb = $PS.find('div.Buttons a.Prev');
			if (!nb.hasClass('Inactive')) {
				if ($PS.hasClass('Continuous') && $PS.hasClass('MultiSlide') && $PS.find('li.Slide.Active').hasClass('First') ){ prevSlide = $PS.find('li.Slide').index( $PS.find('li.Slide.Last') ) } 
				else if ($PS.hasClass('Continuous') && $PS.find('li.Slide.Active').hasClass('First')){ prevSlide = $PS.find('li.Slide').length-1 } 
				else {prevSlide = CSI(this)-1};
			}
			updateSlide(CPI(this),prevSlide);
		});
		$('.PhotoSlider .Buttons .Next').on('click',function(e){ 
			var $PS = $(this).closest('.PhotoSlider');
			var nb = $PS.find('div.Buttons a.Next');
			if (!nb.hasClass('Inactive')) {
				if ($PS.hasClass('Continuous') && $PS.hasClass('MultiSlide') && $PS.find('li.Slide.Active').hasClass('LastSlideToShow')){ nextSlide = 1 } 
				else if ($PS.hasClass('Continuous') && $PS.find('li.Slide.Active').hasClass('Last')){ nextSlide = 1 }
				else {nextSlide = CSI(this)+1};
			}
			updateSlide(CPI(this),nextSlide);
		});

	// list navigation
		$('.PhotoSlider .Buttons .SlideNav li').on('click',function(){
				var navIndex = $(this).parent('ul').find('li').index( $(this) );
				updateSlide(CPI(this),navIndex);
		});
	// for ImageThumbs, on hover, change opacity
		$('.PhotoSlider .Buttons .SlideNav ul.ImageThumbs li')
			.hover(	function(){ $(this).not('.Active').animate({opacity: 1},200) },
					function(){ $(this).not('.Active').animate({opacity: 0.4},200) }
			)
			.on('click',function(){ $(this).css({opacity: 1}) });
			


// ------> Function: Get the outSide Width including padding and margins... I tried using outerWidth(true), but it wasn't working in all browsers
	function getWidth(e){ 
		var w = e.width() + parseInt(e.css('padding-left')) + parseInt(e.css('padding-right')) + parseInt(e.css('margin-right')) +  parseInt(e.css('margin-left')) + parseInt(e.css('borderRightWidth')) + parseInt(e.css('borderLeftWidth'));
		return w;
	}

// ------> Function: Get the outSide Height including padding and margins... I tried using outerHeight(true), but it wasn't working in all browsers
	function getHeight(e){ 
		var h = e.height() + parseInt(e.css('padding-top')) + parseInt(e.css('padding-bottom')) + parseInt(e.css('margin-top')) +  parseInt(e.css('margin-bottom')) + parseInt(e.css('borderTopWidth')) + parseInt(e.css('borderBottomWidth'));
		return h;
	}

// ------> Function: Get the Current PhotoSlider Index
	function CPI(e){ 
		var $PS = $(e).closest('.PhotoSlider'),
			index = $('.PhotoSlider').index( $PS );
		return index;
	}
	
// ------> Function: Get the Current Slide Index
	function CSI(e){
		var $PS = $(e).closest('.PhotoSlider'),
			slideIndex = $PS.find('li.Slide').index( $PS.find('li.Slide.Active') );
		return slideIndex;
	}
// --------- Function: Update to the selected slide

	function updateSlide(index,slideIndex,firstLoaded) {

		$('.PhotoSlider').eq(index).each(function(){
			var $PS = $(this),
				overflow = $PS.find('.Overflow'),
				container = $PS.find('.Container'),
				totalSlides = $PS.find('li.Slide').length,
				animate = 'slide';

		// if SlideIndex is empty, set it to the next slide in order		
			if (slideIndex==null) {
				slideIndex = CSI(this)+1;
				if ($PS.find('li.Slide.Active').hasClass('Last')){ slideIndex = 0 };
			}
			
		// set the new slide to be active
			$PS.find('li.Slide.Active').removeClass('Active');
			$PS.find('li.Slide').eq(slideIndex).addClass('Active');
	
		// update the text - slide numbers and caption
			$PS.find('.Buttons .Count span:first').html( CSI($PS)+1 );
			$PS.find('.Buttons .Count span:last').html( totalSlides );

			// use <span class="Caption">.... if it's empty, then use alt tag from image...
				caption = $PS.find('li.Slide.Active .Caption').html();
				if (caption == null) { caption = $PS.find('li.Slide.Active img').attr('alt'); };
				$PS.find('.PhotoCaption').html( caption );

		// set navigation buttons to inactive where necessary
			if(!$PS.hasClass('Continuous')) {
				if (CSI($PS) == '0') { $PS.find('.Buttons .Prev').addClass('Inactive'); } else { $PS.find('.Buttons .Prev').removeClass('Inactive'); }
				if (CSI($PS)+1 == totalSlides || $PS.find('li.Slide.Active').hasClass('LastSlideToShow') ) { $PS.find('.Buttons .Next').addClass('Inactive'); } else { $PS.find('.Buttons .Next').removeClass('Inactive'); }
			}
			
		// update NavLinks with Active LI
			$PS.find('.SlideNav li.Active').removeClass('Active');
			$PS.find('.SlideNav li').eq(CSI($PS)).addClass('Active');

		// if using Fade transition instead of slide
			if ($PS.hasClass('FadeMe')){
				animate = 'fade';
				$PS.find('li.Slide').css('position','absolute');
				$PS.find('li.Slide').eq(CSI($PS)).addClass('Active').stop()
					.css({
						'opacity':	'0',
						'z-index' : '92'
						});
			// if first loaded, then don't animate, just show the first one	
				if (firstLoaded==true) { 
					$PS.find('li.Slide').eq(CSI($PS))
						.css({'opacity': '1'},function(){ 
							// after animate is complete, update the z-index of the LI's so we can keep going
								$PS.find('li.Slide').not('.Active').css({'z-index':	'90'});
								$PS.find('li.Slide.Active').css('z-index','91');
						});
				} 
				else { 
			// if not first loaded, then animate the fade
					$PS.find('li.Slide').eq(CSI($PS))
						.animate({opacity: 1},function(){ 
							// after animate is complete, update the z-index of the LI's so we can keep going
								$PS.find('li.Slide').not('.Active').css({'z-index':	'90'});
								$PS.find('li.Slide.Active').css('z-index','91');
						});
				}

			} 

	// ----- update slide widths ----- 
		var w = $PS.find('li.Slide.Active').outerWidth(),
			h = $PS.find('li.Slide.Active').outerHeight(),
			slideLW = 0,
			maxH = 0;
			
		$PS.find('li.Slide').each(function(){ if (maxH < $(this).outerHeight()){ maxH = $(this).outerHeight() } });
		
	// set the width (and height, if is Fluid) of Overflow
		// if it's not manual width, then update the width
			if (!$PS.hasClass('manualWidth')){
			// dont animate the photoslider when it's first loaded
				if (firstLoaded==true) { 
					overflow.width(w).height(maxH);
				} 
				else { 
					overflow.animate({ width: w});
				}
			}

// ----------- SHOW NAVIGATION -----------
	if ($PS.hasClass('ShowNav') || $PS.hasClass('Vertical')){
		var buttonBox, buttonArea, vertical = false,
			navSize = 0,
			navHeight = 0,
			navUL = 0,
			biggestLI = 0,
			n = 0,
			thisS = 0,
			img = '',
			perc = .10,
			$Nav = $PS.find('.SlideNav'),
			navW = 0, widestLI = 0, centerNavMargin = 0,
			prevButtonH = $PS.find('.Buttons .Prev').outerHeight(),
			nextButtonH = $PS.find('.Buttons .Next').outerHeight(),
			prevButtonW = $PS.find('.Buttons .Prev').outerWidth(),
			nextButtonW = $PS.find('.Buttons .Next').outerWidth(),
			buttonW, buttonH;
			
	// find widset button
		if (prevButtonH>0 && nextButtonH>0 && prevButtonW>0 && nextButtonW>0){
			if (prevButtonH > nextButtonH){buttonH = prevButtonH} else {buttonH = nextButtonH}
			if (prevButtonW > nextButtonW){buttonW = prevButtonW} else {buttonW = nextButtonW}
		}
		else{
			prevButtonH=0;
			prevButtonW=0;
			nextButtonH=0;
			nextButtonW=0;
		}
	// Calc button area
		if ($PS.hasClass('Vertical')){
			vertical = true;
			buttonBox = $PS.find('.Buttons').parent().height() - parseInt($PS.find('.Buttons').css('padding-top')) - parseInt($PS.find('.Buttons').css('padding-bottom'));
			buttonArea =  buttonBox - ( prevButtonH + nextButtonH );
			$PS.find('.Buttons').height(buttonBox);
		}
		else{
			buttonArea = $PS.find('.Buttons').width() - parseInt($PS.find('.Buttons').css('padding-left')) - parseInt($PS.find('.Buttons').css('padding-right')) - ( prevButtonW + nextButtonW );
		}


	// do Images for Thumbnails
		if ($PS.attr('class').match(/^.*ImageThumbs.*/)!==null){  // if class of ImageThumbs or ImageThumbsXXXX exists then
			$PS.find('.SlideNav ul').addClass('ImageThumbs');
			if ( $PS.attr('class').match(/^.*ImageThumbs(\d+).*/) ){ // if class of ImageThumbsXXXX exists then over-ride the percentage
				perc = $PS.attr('class').match(/^.*ImageThumbs(\d+).*/)[1]*.01;
			} 
		// create the <img> tag and inject it in the nav li
			$Nav.find('li').each(function(e){
				img = $PS.find('li img').eq(e);
				$(this).html('<img src="' + img.attr('src') + '" width="' + img.width()*perc + '" height="' + img.height()*perc + '"/>').not('.Active').css('opacity','0.4');
			});
		} // -- end Images for Thumbnails
		
	// ---- LOOP through the nav li's
		$Nav.find('li').each(function(){
		// Vertical? or horizontal? find the outer size of each li... also, for vertical, set the item to it's height -- to fix a silly FF & IE8 issue where it doesn't calculate the height correctly and cuts of items
			if (vertical){ 
				thisS = getHeight($(this));
				$(this).height( $(this).height() );
			} 
			else {
				thisS = getWidth($(this));
			}
		// get total size of all nav link li's
			navUL += thisS;
		// find the biggest LI in the group - this helps to make sure that the nav always fits between the arrows
			if (thisS > biggestLI) { biggestLI = thisS}
		// find the widest LI in the group - so we can position the vertical nav correctly
			if ($(this).outerWidth(true) > navW) { navW = $(this).outerWidth(true); widestLI = navW; }
		// if the next/prev buttons are bigger, set navW to their width
			if (buttonW > navW ) { navW = buttonW }
		});
	// ----- end LI loop

	// n = the number of nav li's to show -- its the size of the area devided by the widest li (rounded down)
		n = (parseInt(buttonArea/biggestLI));

	// fix ie6 issue where it adds the margin width too much
		var ieWidthFix = 0;
		if ($.browser.msie && $.browser.version.substr(0,1)<7) { ieWidthFix = parseInt($Nav.find('li').css('margin-right')); }

	// ---- TOO MANY LI's TO FIT ----- if the nav ul is too big to fit in the button area - let's shrink it down
		if ( buttonArea < navUL ) {
			
		// how many are showing -- it should be an odd number... make it so
			if (!(n%2)){n=n-1}
			var active = $Nav.find('li.Active'),
				activeIndex = $Nav.find('li').index( active ),
				moveBack = 0,
				pad = (n*.5) - .5, // this is the number of LI's to show on each side of active (when centered)
				start = activeIndex - pad,
				end = start + n,
				verticalPadding = 0;
			if (start<0){ start=0; end = start + n; } // if we're at the first LI
			if (end>totalSlides){ end = totalSlides; start = end - n; } // if we're at the last LI
			
		// slice the LI's to mark which are showing
			$Nav.find('li.ShowNav').removeClass('ShowNav');
			$Nav.find('li').slice(start,end).addClass('ShowNav');
			
		// find the total size of the LI's that are before the .ShowNav so we know how far to move it left
			var firstShowNavIndex = $Nav.find('li').index( $Nav.find('.ShowNav:first') );
			if (vertical){
				// find how far to slide the UL upwards
					$Nav.find('li').slice(0,firstShowNavIndex).each(function(){ moveBack += getHeight($(this)) });
					$Nav.find('li.ShowNav').each(function(){ navSize += getHeight($(this)) });
					$Nav.find('ul').height( navUL+ieWidthFix ).stop().animate({ top: -moveBack });
			}
			else{
				// find how far to slide the UL leftwards
					$Nav.find('li').slice(0,firstShowNavIndex).each(function(){ moveBack +=  getWidth($(this)) });
					$Nav.find('li.ShowNav').each(function(){ navSize += getWidth($(this)); });
					$Nav.find('ul').width( navUL+ieWidthFix ).stop().animate({ left: -moveBack });
			}
		}
	 // if the nav ul isn't too big, then set the SlideNav to the same width so it centers the nav
		else{
			if (vertical){
				$Nav.find('ul').height( navUL+ieWidthFix );
			}
			else{
				$Nav.find('ul').width( navUL+ieWidthFix );
			}
			
			navSize = navUL+ieWidthFix;
		} 
		
	// set nav size
		if (vertical){
			$Nav.height( navSize ).css('top',((buttonArea - navSize) * .5) + $PS.find('.Buttons .Prev').outerHeight());
			if (firstLoaded){
				// set the LI's width to the widest so the text/imgs are centered
					$Nav.find('li').width(widestLI - parseInt($Nav.find('li').css('padding-right')) - parseInt($Nav.find('li').css('padding-left')) - parseInt($Nav.find('li').css('borderRightWidth')) - parseInt($Nav.find('li').css('borderLeftWidth'))); 
				// reset the $PS width to fit the vertical navigation
					buttonBox =  + navW + parseInt($PS.find('.Buttons').css('padding-left')) + parseInt($PS.find('.Buttons').css('padding-right'));
					$PS.width( $(this).width() + buttonBox );
				// silly IE bug
					$PS.find('.Buttons').width(navW);
				// do the width stuff
					if ($PS.hasClass('NavRight')){
						$PS.find('.Overflow,.PhotoCaption').css('margin-right', buttonBox );
						$PS.find('.Border').addClass('NavRight');
						$PS.find('.Buttons').css('margin-left', $PS.find('.Overflow').width() );
					}
					else{
						$PS.find('.Overflow,.PhotoCaption').css('margin-left', buttonBox);
					}
				if (buttonW > widestLI) {
					// center the nav LI using a margin
						centerNavMargin = parseInt((navW - widestLI) * .5);
						$Nav.css('margin-left',centerNavMargin+'px').css('margin-right',centerNavMargin+'px');
				}
				else{
					// center the Prev/Next using a margin
						centerNavMargin = parseInt((navW - buttonW) * .5);
						$Nav.siblings('.Prev,.Next').css('margin-left',centerNavMargin+'px').css('margin-right',centerNavMargin+'px');
				}
			}
		}
	// it's not vertical
		else{
			$Nav.width( navSize );
		}

	} //if ($PS.hasClass('ShowNav')){
// ----------- END SHOW NAVIGATION -----------
	
			// find the outer widths of all left slides	
				$PS.find('li.Slide:lt('+CSI($PS)+')').each(function(){ slideLW += $(this).outerWidth(true); });
				
			if (animate=='slide'){
				// animate the container
					container.stop().animate({ left: -slideLW });
			}
			
		}); // EN $('.PhotoSlider').eq(index).each(function(){
		
	}  // END --= nction updateSlide(index,slideIndex,firstLoaded) =--
	
	
	
	
}

// minify at http://refresh-sf.com/yui/
$.fx.prototype.cur = function(){ // we are overwriting this function to remove limiting of the size of the

    if ( this.elem[this.prop] != null && (!this.elem.style || this.elem.style[this.prop] == null) ) {
      return this.elem[ this.prop ];
    }

    var r = parseFloat( jQuery.css( this.elem, this.prop ) );
    return typeof r == 'undefined' ? 0 : r;
    
}